import { combineReducers } from "redux";
import { loadingReducer } from "./loadingReducer";
import { stationReducer } from "./stationReducer";

const rootReducer = combineReducers({
  loading: loadingReducer,
  station: stationReducer,
});

export default rootReducer;
