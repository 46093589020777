import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";

import Loader from "./components/loader/Loader";
import Footer from "./components/footer/Footer";
import store from "./store/store";

const Home = lazy(() => import("./pages/home/Home"));
const Page404 = lazy(() => import("./pages/404/Page404"));
const PhotoGallery = lazy(() => import("./pages/photo-gallery/PhotoGallery"));
const PoliceStations = lazy(() =>
  import("./pages/police-stations/PoliceStations")
);
const PressRelease = lazy(() => import("./pages/press-release/PressRelease"));
const SeniorOfficers = lazy(() =>
  import("./pages/senior-officers/SeniorOfficers")
);
const HallOfFame = lazy(() => import("./pages/hall-of-fame/HallOfFame"));
const AccidentDetailReceipt = lazy(() =>
  import("./pages/accident-detail-receipt/AccidentDetailReceipt")
);
const AccidentDetailReceiptDetails = lazy(() =>
  import("./pages/accident-detail-receipt/AccidentDetailReceiptDetails")
);
const PhotoGalleryDetails = lazy(() =>
  import("./pages/photo-gallery/PhotoGalleryDetails")
);
const CitizenCharter = lazy(() =>
  import("./pages/citizen-charter/CitizenCharter")
);
const WomenSafety = lazy(() => import("./pages/women-safety/WomenSafety"));
const MohallaCommittee = lazy(() =>
  import("./pages/mohalla-committee/MohallaCommittee")
);
const DisputeFreeVillage = lazy(() =>
  import("./pages/dispute-free-village/DisputeFreeVillage")
);
const VillageDefenceParty = lazy(() =>
  import("./pages/village-defence-party/VillageDefenceParty")
);
const LegalMatters = lazy(() => import("./pages/legal-matters/LegalMatters"));
const DownloadForms = lazy(() =>
  import("./pages/download-forms/DownloadForms")
);
const PolicePatilContacts = lazy(() =>
  import("./pages/police-patil-contacts/PolicePatilContacts")
);
const ProtectionOfCivilRights = lazy(() =>
  import("./pages/protection-of-civil-rights/ProtectionOfCivilRights")
);
const WomenChildrenCell = lazy(() =>
  import("./pages/women-cell/WomenChildrenCell")
);
const DistrictSpecialBranch = lazy(() =>
  import("./pages/district-special-branch/DistrictSpecialBranch")
);
const AntiTerrorismCell = lazy(() =>
  import("./pages/anti-terrorism-cell/AntiTerrorismCell")
);
const BDDS = lazy(() => import("./pages/bdds/BDDS"));
const ControlRoom = lazy(() => import("./pages/control-room/ControlRoom"));
const CCTNS = lazy(() => import("./pages/cctns/CCTNS"));
const EconomicOffensiveWing = lazy(() =>
  import("./pages/economic-offensive-wing/EconomicOffensiveWing")
);
const ForeignerRegistrationOffice = lazy(() =>
  import("./pages/fro/ForeignerRegistrationOffice")
);
const LocalCrimeBranch = lazy(() => import("./pages/lcb/LocalCrimeBranch"));
const MotorTransportSection = lazy(() =>
  import("./pages/motor-transport/MotorTransportSection")
);
const PoliceHeadQuarter = lazy(() =>
  import("./pages/police-head-quarter/PoliceHeadQuarter")
);
const TrafficBranch = lazy(() =>
  import("./pages/traffic-branch/TrafficBranch")
);
const WirelessSection = lazy(() => import("./pages/wireless/WirelessSection"));
const NoisePollution = lazy(() =>
  import("./pages/noise-pollution/NoisePollution")
);
const ScheduledCastsTribe = lazy(() =>
  import("./pages/scheduled-cast/ScheduledCastsTribe")
);
const RightToInformation = lazy(() =>
  import("./pages/right-to-information/RightToInformation")
);
const TrafficRules = lazy(() => import("./pages/traffic-rules/TrafficRules"));
const TrafficFines = lazy(() => import("./pages/traffic-fines/TrafficFines"));
const ContactUs = lazy(() => import("./pages/contact-us/ContactUs"));
const ComingSoon = lazy(() => import("./pages/coming-soon/ComingSoon"));
const OrganizationStructure = lazy(() =>
  import("./pages/organization-structure/OrganizationStructure")
);
const AboutDistrict = lazy(() =>
  import("./pages/about-district/AboutDistrict")
);
const ActsRules = lazy(() => import("./pages/acts-and-rules/ActsRules"));
const Recruitments = lazy(() => import("./pages/recruitments/Recruitments"));
const PositiveStories = lazy(() =>
  import("./pages/positive-stories/PositiveStories")
);
const AllInitiatives = lazy(() => import("./pages/initiatives/AllInitiatives"));
const Initiative = lazy(() => import("./pages/initiatives/Initiative"));

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/photo-gallery" component={PhotoGallery} />
            <Route
              path="/photo-gallery-details/:id"
              component={PhotoGalleryDetails}
            />
            <Route path="/police-stations" component={PoliceStations} />
            <Route path="/press-release" component={PressRelease} />
            <Route path="/senior-officers" component={SeniorOfficers} />
            <Route path="/hall-of-fame" component={HallOfFame} />
            <Route
              path="/accident-detail-receipt"
              component={AccidentDetailReceipt}
            />
            <Route
              path="/accident-detail-receipt-details/:id"
              component={AccidentDetailReceiptDetails}
            />
            <Route path="/citizen-charter" component={CitizenCharter} />
            <Route path="/women-safety" component={WomenSafety} />
            <Route path="/mohalla-committee" component={MohallaCommittee} />
            <Route
              path="/dispute-free-village"
              component={DisputeFreeVillage}
            />
            <Route
              path="/village-defence-party"
              component={VillageDefenceParty}
            />
            <Route path="/legal-matters" component={LegalMatters} />
            <Route path="/download-forms" component={DownloadForms} />
            <Route
              path="/police-patil-contacts"
              component={PolicePatilContacts}
            />
            <Route
              path="/protection-of-civil-rights"
              component={ProtectionOfCivilRights}
            />
            <Route path="/women-cell" component={WomenChildrenCell} />
            <Route
              path="/district-special-branch"
              component={DistrictSpecialBranch}
            />
            <Route path="/anti-terrorism-cell" component={AntiTerrorismCell} />
            <Route path="/bdds" component={BDDS} />
            <Route path="/control-room" component={ControlRoom} />
            <Route path="/cctns" component={CCTNS} />
            <Route
              path="/economic-offensive-wing"
              component={EconomicOffensiveWing}
            />
            <Route path="/fro" component={ForeignerRegistrationOffice} />
            <Route path="/lcb" component={LocalCrimeBranch} />
            <Route path="/motor-transport" component={MotorTransportSection} />
            <Route path="/police-head-quarter" component={PoliceHeadQuarter} />
            <Route path="/traffic-branch" component={TrafficBranch} />
            <Route path="/wireless" component={WirelessSection} />
            <Route path="/noise-pollution" component={NoisePollution} />
            <Route path="/scheduled-cast" component={ScheduledCastsTribe} />
            <Route
              path="/right-to-information"
              component={RightToInformation}
            />
            <Route path="/traffic-rules" component={TrafficRules} />
            <Route path="/traffic-fines" component={TrafficFines} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/recruitments" component={Recruitments} />
            <Route path="/positive-stories" component={PositiveStories} />
            <Route path="/acts-and-rules" component={ActsRules} />
            <Route
              path="/organization-structure"
              component={OrganizationStructure}
            />
            <Route path="/about-district" component={AboutDistrict} />
            <Route path="/initiatives" component={AllInitiatives} />
            <Route path="/initiative/:id" component={Initiative} />
            <Route path="*" component={Page404} />
          </Switch>
        </Router>
        <Footer />
      </Provider>
    </Suspense>
  );
};

export default App;
