import React, { PureComponent } from "react";

class Footer extends PureComponent {
  openLinkInNewTab = (e) => {
    if (window.confirm("Are you sure you want to open this link?")) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  render() {
    return (
      <footer className="footer">
        <div className="row mx-0">
          <div className="col-md-3">
            <h3 className="footer__heading">Twitter Feed</h3>
            <a
              className="twitter-timeline"
              data-width=""
              data-height="350"
              href="https://twitter.com/SpDhule?ref_src=twsrc%5Etfw"
            >
              Tweets by SpDhule
            </a>
          </div>
          <div className="col-md-3">
            <h3 className="footer__heading">Facebook Feed</h3>
            <div
              className="fb-page"
              data-href="https://www.facebook.com/Dhule-Police-523214061194478/"
              data-tabs="timeline"
              data-width=""
              data-height="350"
              data-small-header="true"
              data-adapt-container-width="true"
              data-hide-cover="true"
              data-show-facepile="true"
            >
              <blockquote
                cite="https://www.facebook.com/Dhule-Police-523214061194478/"
                className="fb-xfbml-parse-ignore"
              >
                <a href="https://www.facebook.com/Dhule-Police-523214061194478/">
                  Dhule Police
                </a>
              </blockquote>
            </div>
          </div>
          <div className="col-md-3 mt-3 mt-md-0">
            <h3 className="footer__heading">Quick Links</h3>
            <ul className="footer__list">
              
              <li className="footer__item">
                <a
                  href="https://www.mygov.in/aarogya-setu-app/"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Aarogya Setu Application
                </a>
              </li>
              <li className="footer__item">
                <a href="/acts-and-rules" className="footer__link">
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Acts & Rules
                </a>
              </li>
              <li className="footer__item">
                <a href="/download-forms" className="footer__link">
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Download Forms
                </a>
              </li>
              <li className="footer__item">
                <a href="/protection-of-civil-rights" className="footer__link">
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Protection of Civil Rights
                </a>
              </li>
              <li className="footer__item">
                <a href="/police-patil-contacts" className="footer__link">
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Police Patil Contacts
                </a>
              </li>
              <li className="footer__item">
                <a href="/village-defence-party" className="footer__link">
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Village Defence Party
                </a>
              </li>
              <li className="footer__item">
                <a href="/dispute-free-village" className="footer__link">
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Dispute Free Village
                </a>
              </li>
              <li className="footer__item">
                <a href="/mohalla-committee" className="footer__link">
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Mohalla Committee's
                </a>
              </li>
              <li className="footer__item">
                <a href="/legal-matters" className="footer__link">
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Legal Matters
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="https://play.google.com/store/apps/details?id=com.snt.uniqueguard&hl=en_IN"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Pratisaad Application
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h3 className="footer__heading">Important Links</h3>
            <ul className="footer__list">
              <li className="footer__item">
                <a
                  href="http://www.mahapolice.gov.in/"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Maharashtra State Police
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="http://dhule.nic.in/"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Collector Office, Dhule
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="https://jalgaonpolice.gov.in"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Jalgaon District Police
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="http://nashikpolice.com/"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Nashik City Police
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="/"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Nandurbar Police
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="http://www.nashikruralpolice.gov.in/"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Nashik Gramin Police
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="http://police.gov.in/"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Indian Police In Service of The Nation
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="https://www.mahafireservice.gov.in/Site/Home/Home.Aspx"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Maharashtra Fire Services
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="https://njdg.ecourts.gov.in/njdgnew/"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  NJDG | National Judicial Data Grid
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="http://cbi.gov.in/"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Central Bureau of Investigation
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="http://mahacid.com/"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Criminal Investigation Department, Pune
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="http://acbmaharashtra.gov.in/"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Anti Corruption Bureau, Maharashtra
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="https://bombayhighcourt.nic.in/"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Bombay High Court
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="https://www.maharashtra.gov.in/site/common/GovernmentResolutions.aspx"
                  className="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.openLinkInNewTab(e)}
                >
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  Maharashtra Government GR
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright">
          <p className="copyright__text">© Copyright District Police, Dhule</p>
          <p className="copyright__text">
            Designed & Developed by Siddhi Software Solutions, Jalgaon
          </p>
          <p className="copyright__text">
           
           
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
