import {
  SET_POLICE_STATION_ID,
  RESET_POLICE_STATION_ID,
} from "./../actions/actions";

const initialState = {
  policeStationId: 0,
};

export const stationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POLICE_STATION_ID:
      return { policeStationId: action.payload };
    case RESET_POLICE_STATION_ID:
      return { policeStationId: 0 };
    default:
      return { ...state };
  }
};
