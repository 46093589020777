import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/font-awesome/font-awesome-4.7.0/css/font-awesome.min.css";
import "./index.scss";
import App from "./App";

axios.defaults.baseURL = "https://panel.dhulepolice.gov.in/";

ReactDOM.render(<App />, document.getElementById("root"));
